<template>
  <div
    class="w-full max-w-lg relative mx-auto py-8 px-4"
  >
    Resetting...
  </div>
</template>

<script>

export default {
  mounted() {
    localStorage.clear()
    window.location = '/sign-in'
  }
}
</script>
